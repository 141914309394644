import { Fragment, MouseEventHandler, useState } from 'react'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import CaretDownIcon from '../icons/caret-down'
import CaretUpIcon from '../icons/caret-up'
import { SidebarRoute } from '../../configs/routes'

const listItemOpacity = css`
  opacity: 0.6;
`

const StyledGatsbyLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const StyledListItem = styled(ListItemButton)`
  ${listItemOpacity}
`

const StyledListItemCollapsed = styled(ListItemButton)`
  ${listItemOpacity}
  padding-top: 3;
  padding-bottom: 3;
`

const SidebarLinkCollapse = ({ route }: { route: SidebarRoute }) => {
  const [open, setOpen] = useState(false)
  const handleClick: MouseEventHandler<HTMLDivElement> = () => {
    setOpen(!open)
  }
  return (
    <Box component="div" mb="18px">
      <StyledListItem style={{ opacity: 0.6 }} onClick={handleClick}>
        <ListItemIcon>{route.icon}</ListItemIcon>
        <ListItemText
          primary={route.name}
          primaryTypographyProps={{
            style: { fontWeight: 600, fontSize: 16 },
          }}
        />
        {open ? <CaretUpIcon /> : <CaretDownIcon />}
      </StyledListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {route.children &&
          route.children.map(child => (
            <Fragment key={child.name}>
              {child.enabled && (
                <List disablePadding>
                  <StyledGatsbyLink to={child.path}>
                    <StyledListItemCollapsed>
                      <Box minWidth="56px" />
                      <ListItemText
                        primary={child.name}
                        primaryTypographyProps={{
                          style: { fontWeight: 600, fontSize: 16 },
                        }}
                      />
                    </StyledListItemCollapsed>
                  </StyledGatsbyLink>
                </List>
              )}
            </Fragment>
          ))}
      </Collapse>
    </Box>
  )
}

export default SidebarLinkCollapse
