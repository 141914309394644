import { useAuth0 } from '@auth0/auth0-react'
import { Box, styled } from '@mui/material'
import axios from 'axios'
import { navigate } from 'gatsby'
import Cookie from 'js-cookie'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { ToastContainer, Flip, toast } from 'react-toastify'
import { useMediaQuery } from 'react-responsive'

import Sidebar from './sidebar'
import Navbar from './navbar'
import Drawer from './drawer'
import Spinner from './loading/spinner'
import { SidebarRoute, getSidebarRoutes } from '../configs/routes'
import withValidActiveRole from '../hoc/withValidActiveRole'
import {
  Association,
  useLazyGetAssociationQuery,
} from '../services/api/endpoints/associations'
import {
  SubMaster,
  useLazyGetSubMasterQuery,
} from '../services/api/endpoints/sub-masters'
import { isEmptyObj } from '../utils/string'
import { ActiveRole } from '../utils/types'

interface LayoutProps {
  children: ReactNode
  url?: string
  label1?: string
  label2?: string
}

const StyledMainPanel = styled('div')`
  ${({ theme }) => {
    const drawerWidth = 250
    return {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    }
  }}
  overflow: auto;
  position: relative;
  float: right;
  max-height: 100%;
  width: 100%;
  --webkit-overflow-scrolling: touch;
`

const StyledContent = styled('div')`
  margin-top: 70px;
  padding: 30px 15px;
  min-height: calc(100vh - 123px);
`

const StyledContainer = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(561)]: {
        paddingRight: '0rem',
        paddingLeft: '0rem',
      },
    }
  }}
  padding-right: 3rem;
  padding-left: 3rem;
  margin-right: auto;
  margin-left: auto;
`

const LayoutWrapper = styled('div')`
  position: relative;
  top: 0;
  height: 100vh;
`

const Layout = ({
  children,
  label1,
  label2,
  url,
}: LayoutProps): ReactElement => {
  const { user, logout } = useAuth0()
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const [isLoading, setIsLoading] = useState(true)
  const [activeRole, setActiveRole] = useState<ActiveRole | null>(null)
  const [entity, setEntity] = useState<Association | SubMaster | null>(null)

  const [
    getAssociation,
    { data: association, isLoading: isAssociationLoading },
  ] = useLazyGetAssociationQuery()
  const [getSubMaster, { data: subMaster, isLoading: isSubMasterLoading }] =
    useLazyGetSubMasterQuery()

  useEffect(() => {
    const token = Cookie.get('token')
    if (user) {
      const userMetaData = user['https://tritagrugby.com/user_metadata']
      if (!user.family_name || !user.given_name) {
        navigate('/complete-profile')
      } else if (isEmptyObj(userMetaData)) {
        navigate('/not-authorised')
      } else {
        const access = userMetaData.access.map(
          (role: any) => role.entityId as String,
        )

        if (access.length && !userMetaData.activeRole) {
          const metaData = {
            access: userMetaData.access,
            activeRole: userMetaData.access[0],
          }
          const options = {
            method: 'PATCH',
            url: `https://api.tritagrugby.com/api/users/${user.sub}`,
            headers: {
              // this is only a test token
              mahanginako: token,
              'content-type': 'application/json',
            },
            data: {
              user_metadata: metaData,
            },
          }
          alignRole(options)
        } else if (!access.includes(userMetaData.activeRole.entityId)) {
          const metaData = {
            access: userMetaData.access,
            activeRole: userMetaData.access[0],
          }
          const options = {
            method: 'PATCH',
            url: `https://api.tritagrugby.com/api/users/${user.sub}`,
            headers: {
              // this is only a test token
              mahanginako: token,
              'content-type': 'application/json',
            },
            data: {
              user_metadata: metaData,
            },
          }
          alignRole(options)
        } else if (userMetaData.activeRole) {
          setActiveRole(userMetaData.activeRole)
        }

        setIsLoading(false)
      }
    }
  }, [user])

  const alignRole = async (options: any) => {
    await axios
      .request(options)
      .then(() => {
        logout()
      })
      .catch(() => {
        toast.error('Something went wrong, please try again.')
        logout()
      })
  }

  useEffect(() => {
    if (activeRole && activeRole.type === 2) {
      getSubMaster(activeRole.entityId)
    } else if (activeRole && activeRole.type === 3) {
      getAssociation(activeRole.entityId)
    }
  }, [activeRole, getAssociation, getSubMaster])

  useEffect(() => {
    if (!isAssociationLoading && association) {
      setEntity(association)
    }
  }, [association, isAssociationLoading])

  useEffect(() => {
    if (!isSubMasterLoading && subMaster) {
      setEntity(subMaster)
    }
  }, [subMaster, isSubMasterLoading])

  let sidebarRoutes: SidebarRoute[] = []
  if (user && user['https://tritagrugby.com/user_metadata']?.activeRole) {
    sidebarRoutes = getSidebarRoutes(
      user['https://tritagrugby.com/user_metadata'].activeRole,
      entity,
    )
  }

  return (
    <LayoutWrapper>
      <ToastContainer
        autoClose={3000}
        transition={Flip}
        className="toast-container"
        theme="colored"
        toastClassName="dark-toast"
        position="top-right"
      />
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : isMobile ? (
        <>
          <Drawer routes={sidebarRoutes} />
          <ConditionalContent>
            <StyledContent>
              <StyledContainer>{children}</StyledContainer>
            </StyledContent>
          </ConditionalContent>
        </>
      ) : (
        <>
          {user && <Sidebar routes={sidebarRoutes} />}
          <StyledMainPanel>
            <Navbar url={url!} label1={label1} label2={label2} />
            <ConditionalContent>
              <StyledContent>
                <StyledContainer>{children}</StyledContainer>
              </StyledContent>
            </ConditionalContent>
          </StyledMainPanel>
        </>
      )}
    </LayoutWrapper>
  )
}

const ConditionalContent = withValidActiveRole(({ children }: any) => children)

Layout.defaultProps = {
  url: '/',
  label1: undefined,
  label2: undefined,
}

export default Layout
