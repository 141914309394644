import { SvgIconProps } from '@mui/material'
import { SvgIconNoFill } from './styles'

const CompetitionsIcon = (props: SvgIconProps) => (
  <SvgIconNoFill {...props}>
    <path
      d="M12 15C15.866 15 19 11.866 19 8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8C5 11.866 8.13401 15 12 15Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.21 13.8899L7 22.9999L12 19.9999L17 22.9999L15.79 13.8799"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconNoFill>
)

export default CompetitionsIcon
