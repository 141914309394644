import { convertTime12to24 } from './time'

export const convertCamelToStr = (value: string) => {
  value = value.replace(/([A-Z])/g, ' $1')
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const sortLadderOrder = (sortOrder: any) => {
  const array = []
  array[sortOrder.points.order - 1] = 'points'
  array[sortOrder.pointsDifference.order - 1] = 'pointsDifference'
  array[sortOrder.pointsFor.order - 1] = 'pointsFor'
  array[sortOrder.winRatio.order - 1] = 'winRatio'

  return array
}

export const noLimitParams = {
  offset: 0,
  limit: 100000,
  search: '',
}

export const getStartTime = (time: string) => {
  const str = time.split(' - ')
  return convertTime12to24(str[0])
}

export const getEndTime = (time: string) => {
  const str = time.split(' - ')
  return convertTime12to24(str[1])
}

export const isEmptyObj = (obj: any) => {
  if (!obj) return true
  if (Object.keys(obj).length === 0) return true
  if (obj.access) {
    if (!obj.access.length) return true
  }
}

export const roleLabel = (type: number) => {
  switch (type) {
    case 1:
      return 'Master Admin'
    case 2:
      return 'Sub Master Admin'
    case 3:
      return 'Association Admin'

    default:
      return 'Undefined Admin'
  }
}

export const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
