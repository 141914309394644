import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import NotAuthorized from 'manage-tritag/components/not-authorized'

const withValidActiveRole = (WrappedComponent: React.FC<any>) => {
  const WithValidActiveRole = (props: any) => {
    const { user } = useAuth0()

    const [authorized, setAuthorized] = useState<boolean>(false)

    useEffect(() => {
      if (user) {
        const userMetaData = user['https://tritagrugby.com/user_metadata']
        if (userMetaData?.activeRole?.active === true) {
          setAuthorized(true)
        } else {
          setAuthorized(false)
        }
      } else {
        setAuthorized(false)
      }
    }, [user])

    return authorized ? <WrappedComponent {...props} /> : <NotAuthorized />
  }

  return WithValidActiveRole
}

export default withValidActiveRole
