import { SvgIcon, SvgIconProps } from '@mui/material'

const ReportingIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12 20V10"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 20V4"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 20V16"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default ReportingIcon
