export const tConv24 = (time24: string) => {
  let ts = time24
  const H = +ts.substr(0, 2)
  let h: string | number = H % 12 || 12
  h = h < 10 ? `0${h}` : h // leading 0 at the left for 1 digit hours
  const ampm = H < 12 ? ' AM' : ' PM'
  ts = h + ts.substr(2, 3) + ampm
  return ts
}

export const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(' ')

  const splitTime = time.split(':')
  let hours = splitTime[0]
  const minutes = splitTime[1]
  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'PM') {
    hours = `${parseInt(hours, 10) + 12}`
  }
  return `${hours}:${minutes}`
}
