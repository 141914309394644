import { api } from '..'

export interface Address {
  formatted: string
  line_1: string
  line_2: string
  city: string
  state: string
  postcode: string
  country: string
  location: {
    lat: number
    lng: number
  }
}

export interface SubMaster {
  _id: string
  name: string
  ttr_id: string
  primaryContact: {
    name: string
    email: string
    phone: string
  }
  address: Address
  abn: number
  logo: string
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type SubMastersResponse = SubMaster[]

export const subMastersApi = api.injectEndpoints({
  endpoints: build => ({
    getSubMasters: build.query<SubMastersResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: 'sub-masters/',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
      providesTags: ['sub-masters'],
    }),
    getSubMaster: build.query<SubMaster, string>({
      query: id => `sub-masters/${id}`,
      providesTags: ['sub-master'],
    }),
    addSubMaster: build.mutation({
      query: body => ({
        url: 'sub-masters',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['sub-masters'],
    }),
    updateSubMaster: build.mutation({
      query: body => {
        const formData = JSON.parse(body.get('formData'))
        return {
          url: `sub-masters/${formData.id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['sub-masters', 'sub-master'],
    }),
    deleteSubMaster: build.mutation({
      query: id => ({
        url: `sub-masters/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['sub-masters', 'sub-master'],
    }),
  }),
})

export const {
  useGetSubMastersQuery,
  useGetSubMasterQuery,
  useAddSubMasterMutation,
  useUpdateSubMasterMutation,
  useDeleteSubMasterMutation,
  useLazyGetSubMasterQuery,
  useLazyGetSubMastersQuery,
} = subMastersApi
