import { CircularProgress, Box } from '@mui/material'
import { FC } from 'react'

interface SpinnerProps {
  color?: string
  size: number
}

const Spinner: FC<SpinnerProps> = ({ size, color }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress size={size} sx={{ color }} />
    </Box>
  )
}

Spinner.defaultProps = {
  color: '#fff',
}

export default Spinner
