import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material'
import { Link } from 'gatsby'
import { SidebarRoute } from '../../configs/routes'

const StyledGatsbyLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const StyledListItem = styled(ListItemButton)`
  opacity: 0.6;
  margin-bottom: 18px;
`

const SidebarLink = ({ route }: { route: SidebarRoute }) => (
  <StyledGatsbyLink to={route.path}>
    <StyledListItem>
      <ListItemIcon>{route.icon}</ListItemIcon>
      <ListItemText
        primary={route.name}
        primaryTypographyProps={{
          style: { fontWeight: 600, fontSize: 16 },
        }}
      />
    </StyledListItem>
  </StyledGatsbyLink>
)

export default SidebarLink
