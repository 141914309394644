import { forwardRef, MouseEvent, useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useAuth0 } from '@auth0/auth0-react'
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  styled,
  Menu,
  MenuItem,
  Link,
  Breadcrumbs,
  Divider,
  Grid,
  ListItem,
  ListItemProps,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import axios from 'axios'
import { navigate } from 'gatsby'

const StyledAppBar = styled(AppBar)`
  background-color: transparent;
  box-shadow: none;
  border-bottom: 0;
  margin-bottom: 0;
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
  color: #555555;
  border: 0;
  border-radius: 3px;
  padding: 10px 0;
  padding-left: 2.5rem;
  padding-right: 1rem;
  transition: all 150ms ease 0s;
  min-height: 50px;
  display: block;
`

const StyledToolbar = styled(Toolbar)`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  min-height: 50px;
`

const IgnoreDisabledListItem = forwardRef<any, any>(
  function IgnoreDisabledListItem({ disabled, ...other }: ListItemProps, ref) {
    return <ListItem {...other} ref={ref} />
  },
)

const NavbarProfileOptions = () => {
  const { user, logout, getAccessTokenSilently } = useAuth0()
  const [userMetadata, setUserMetadata] = useState(null)
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)

  useEffect(() => {
    if (user) {
      if (!user.family_name || !user.given_name) {
        navigate('/complete-profile')
      }
      setUserMetadata(user['https://tritagrugby.com/user_metadata'])
    }
  }, [user])

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async (ignoreCache: boolean = false) => {
    const token = await getAccessTokenSilently({ ignoreCache })
    Cookies.set('token', token)
  }

  const updateMetadata = async (accessEntity: any) => {
    const token = await getAccessTokenSilently()
    const domain = process.env.AUTH0_DOMAIN ?? 'dev-tritag.au.auth0.com'

    const options = {
      method: 'PATCH',
      url: `https://${domain}/api/v2/users/${user.sub}`,
      headers: {
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      data: {
        user_metadata: {
          activeRole: accessEntity,
        },
      },
    }

    await axios
      .request(options)
      .then(async function (response) {
        await fetchUser(true)
        setUserMetadata(response.data.user_metadata)
        window.location.href = '/'
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(undefined)
  }

  const handleSwitch = (accessEntity: any) => {
    updateMetadata(accessEntity)
  }

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    })
  }

  return (
    <>
      {user ? (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            alt="Default"
            src={user.picture}
            style={{ marginRight: 14, backgroundColor: '#008174' }}
          >
            {user.name}
          </Avatar>
          <Typography style={{ fontWeight: 600, fontSize: 18 }}>
            {user.given_name} {user.family_name}
          </Typography>
          <IconButton aria-haspopup="true" onClick={handleClick} size="large">
            <MoreVertIcon style={{ fill: '#000000' }} />
          </IconButton>
        </Box>
      ) : (
        'No User'
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <IgnoreDisabledListItem disabled dense>
          {userMetadata && userMetadata.access && userMetadata.access.length
            ? 'Switch role'
            : 'No Roles Assigned'}
        </IgnoreDisabledListItem>
        {userMetadata &&
          userMetadata.access &&
          userMetadata.access.map(accessEntity => (
            <MenuItem
              key={accessEntity.entityId}
              onClick={() => handleSwitch(accessEntity)}
              disabled={
                userMetadata.activeRole
                  ? userMetadata.activeRole.entityId === accessEntity.entityId
                  : false
              }
            >
              <Grid container direction="row" spacing={2}>
                <Grid item xs={3}>
                  {accessEntity.label}
                </Grid>
                <Grid container item xs={9} direction="column">
                  <Grid item>{accessEntity.title}</Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {accessEntity.subTitle}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        <Divider />
        <MenuItem onClick={handleLogout}>Sign out</MenuItem>
      </Menu>
    </>
  )
}

interface NavbarProps {
  url: string
  label1?: string
  label2?: string
}

const Navbar = ({ label1, label2, url }: NavbarProps) => {
  const { isAuthenticated } = useAuth0()
  return (
    <StyledAppBar>
      <StyledToolbar>
        <Box flexGrow={1}>
          <Breadcrumbs aria-label="breadcrumb">
            {label1 && (
              <Link
                sx={{
                  textDecoration: 'none',
                  color: label2 ? 'inherit' : '#555555',
                  cursor: 'pointer',
                }}
                onClick={() => navigate(url)}
              >
                <Typography
                  sx={{ fontWeight: label2 ? 100 : 600, fontSize: 18 }}
                >
                  {label1}
                </Typography>
              </Link>
            )}
            {label2 && (
              <Link
                sx={{
                  textDecoration: 'none',
                  color: '#555555',
                }}
              >
                <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
                  {label2}
                </Typography>
              </Link>
            )}
            {/* <Typography color="textPrimary">WIP</Typography> */}
          </Breadcrumbs>
        </Box>
        {isAuthenticated ? <NavbarProfileOptions /> : null}
      </StyledToolbar>
    </StyledAppBar>
  )
}

Navbar.defaultProps = {
  label1: undefined,
  label2: undefined,
}

export default Navbar
