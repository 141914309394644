import { Box, Drawer, styled } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { SidebarRoute } from '../../configs/routes'
import SidebarLinks from './sidebar-links'

interface SidebarProps {
  routes: SidebarRoute[]
}

const drawerWidth = css`
  width: 250px;
`

const SidebarWrapper = styled('div')``

const StyledDrawer = styled(Drawer)`
  && {
    ${drawerWidth}
    flex-shrink: 0;
  }

  .MuiDrawer-paper {
    ${drawerWidth}
    background-color: #008174;
    color: #fff;
    border: none;
    border-bottom-right-radius: 50px;
  }
`

const StyledBrand = styled(Box)`
  display: flex;
  justify-content: center;
`

const Sidebar = ({ routes }: SidebarProps) => {
  const brand = (
    <StyledBrand component="div" my={3}>
      <StaticImage
        src="../../images/logo_white.png"
        alt="logo"
        placeholder="blurred"
      />
    </StyledBrand>
  )
  return (
    <SidebarWrapper>
      <StyledDrawer variant="permanent" anchor="left">
        {brand}
        <SidebarLinks routes={routes} />
      </StyledDrawer>
    </SidebarWrapper>
  )
}

export default Sidebar
