import { Box, Typography, styled } from '@mui/material'

const StyledBox = styled(Box)`
  display: flex;
  padding: 1rem;
  margin-top: 8rem;
  align-items: center;
  flex-direction: column;
`

const NotAuthorized = () => {
  return (
    <StyledBox>
      <div>
        <div>
          <img
            src="https://tritagrugbypublic.s3.ap-southeast-2.amazonaws.com/logo.png"
            alt="logo"
            style={{
              maxWidth: 200,
              marginBottom: '1.5rem',
            }}
          />
        </div>
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Unauthorised
        </Typography>
        <Typography variant="h5" style={{ marginBottom: '2rem' }}>
          You do not have the required user permissions to access this page
        </Typography>
        <Typography variant="h5" style={{ marginBottom: '2rem' }}>
          Try switching roles or contact your competition administrator to be
          granted access.
        </Typography>
      </div>
    </StyledBox>
  )
}

export default NotAuthorized
