import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { styled } from '@mui/material/styles'
import {
  Drawer,
  AppBar,
  Toolbar,
  Divider,
  IconButton,
  Box,
} from '@mui/material/'
import MenuIcon from '@mui/icons-material/Menu'
import { SidebarRoute } from '../../configs/routes'
import SidebarLinks from '../sidebar/sidebar-links'

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: #008174 !important;
    color: #fff;
    border: none;
    border-bottom-right-radius: 50px;
    width: 250px;
  }
`

const StyledBrand = styled(Box)`
  display: flex;
  justify-content: center;
`

const StyledAppBar = styled(AppBar)`
  ${({ theme }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#008174',
  })}
`

interface SidebarProps {
  routes: SidebarRoute[]
}

const SidebarDrawer = ({ routes }: SidebarProps) => {
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <StyledAppBar position="fixed">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon sx={{ fontSize: '2rem ' }} />
          </IconButton>

          <StaticImage
            src="../../images/brand_mark.png"
            alt="logo"
            height={25}
            width={40}
            placeholder="blurred"
          />

          <Box component="div" style={{ display: 'flex' }}>
            {/* <AvatarImg
              src={avatar}
              alt="logo"
              height={25}
              width={40}
              placeholder="blurred"
            /> */}
          </Box>
        </Toolbar>
      </StyledAppBar>
      <StyledDrawer
        variant="temporary"
        onBackdropClick={handleDrawerClose}
        anchor="left"
        open={open}
      >
        <StyledBrand component="div" my={3}>
          <StaticImage
            src="../../images/logo_white.png"
            alt="logo"
            placeholder="blurred"
          />
        </StyledBrand>

        <Divider />
        <SidebarLinks routes={routes} />
      </StyledDrawer>
    </>
  )
}

export default SidebarDrawer
