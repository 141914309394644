import { api } from '..'
import { SubMaster } from './sub-masters'

export interface Address {
  formatted: string
  line_1: string
  line_2: string
  city: string
  state: string
  postcode: string
  country: string
  location: {
    lat: number
    lng: number
  }
}

export type AssociationStatus = 'Draft' | 'Approved' | 'Rejected' | 'Disabled'
export interface Association {
  _id: string
  ttr_id: string
  name: string
  subMaster: SubMaster
  primaryContact: {
    name: string
    email: string
    phone: string
  }
  secondaryContact: {
    name: string
    email: string
    phone: string
  }
  address: Address
  bank: {
    accountName: string
    bsb: string
    accNo: string
  }
  abn: number
  posId: string
  logo: string
  school: string
  gstRegistered: string
  status: AssociationStatus
  stripeAccountId: string | null
  stripeChargesEnabled?: boolean
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type AssociationsResponse = Association[]

type StripeLinkDetail = {
  object: string
  created: number
  expires_at: number
  url: string
}

type StripeLinks = {
  onboarding: StripeLinkDetail
}

type StripeLinksResponse = {
  success: boolean
  message: string
  data: StripeLinks
}

export const associationsApi = api.injectEndpoints({
  endpoints: build => ({
    getAssociations: build.query<AssociationsResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: 'associations/',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
      providesTags: ['associations'],
    }),
    getAssociation: build.query<Association, string>({
      query: id => `associations/${id}`,
      providesTags: ['association'],
    }),
    getAssociationStripeLinks: build.query<StripeLinksResponse, string>({
      query: id => `associations/${id}/stripe-links`,
      providesTags: ['association-stripe-links'],
    }),
    addAssociation: build.mutation({
      query: body => ({
        url: 'associations',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['associations'],
    }),
    updateAssociation: build.mutation({
      query: body => {
        const formData = JSON.parse(body.get('formData'))
        return {
          url: `associations/${formData.id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['associations', 'association'],
    }),
    deleteAssociation: build.mutation({
      query: id => ({
        url: `associations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['associations'],
    }),
  }),
})

export const {
  useGetAssociationsQuery,
  useGetAssociationQuery,
  useAddAssociationMutation,
  useUpdateAssociationMutation,
  useDeleteAssociationMutation,
  useLazyGetAssociationQuery,
  useLazyGetAssociationsQuery,
  useLazyGetAssociationStripeLinksQuery,
} = associationsApi
