import { SvgIconProps } from '@mui/material'
import { SvgIconNoFill } from './styles'

const DashboardIcon = (props: SvgIconProps) => (
  <SvgIconNoFill {...props}>
    <path
      d="M21.21 15.8901C20.5738 17.3946 19.5788 18.7203 18.3119 19.7514C17.045 20.7825 15.5448 21.4875 13.9424 21.8049C12.3401 22.1222 10.6844 22.0422 9.12015 21.5719C7.55587 21.1015 6.13062 20.2551 4.96902 19.1067C3.80741 17.9583 2.94481 16.5428 2.45663 14.984C1.96845 13.4252 1.86956 11.7706 2.1686 10.1647C2.46763 8.55886 3.15549 7.05071 4.17204 5.77211C5.18859 4.49351 6.50288 3.4834 8 2.83008"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V12H22Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIconNoFill>
)

export default DashboardIcon
