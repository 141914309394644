import { ReactElement } from 'react'
import DashboardIcon from '../components/icons/dashboard'
import UserManagementIcon from '../components/icons/user-management'
import CommunicationIcon from '../components/icons/communication'
import ReportingIcon from '../components/icons/reporting'
import EntitiesIcon from '../components/icons/entities'
import ParticipationIcon from '../components/icons/participation'
import CompetitionsIcon from '../components/icons/competitions'
import ConfigurationsIcon from '../components/icons/configurations'
import PaymentsIcon from '../components/icons/payments'
import { Association } from '../services/api/endpoints/associations'
import { SubMaster } from '../services/api/endpoints/sub-masters'
import { ActiveRole } from '../utils/types'

interface Children {
  path: string
  name: string
  enabled?: boolean
}

export interface SidebarRoute {
  path: string
  name: string
  icon: ReactElement
  children?: Children[]
  enabled?: boolean
}

export const getSidebarRoutes = (
  activeRole: ActiveRole,
  entity: Association | SubMaster | null,
): SidebarRoute[] => {
  return [
    {
      path: '/',
      name: 'Dashboard',
      icon: <DashboardIcon />,
      enabled: true,
    },
    {
      path: '/user-management',
      name: 'User Management',
      icon: <UserManagementIcon />,
      enabled: [1].includes(activeRole.type),
    },
    {
      path: '/communications',
      name: 'Communications',
      icon: <CommunicationIcon />,
      enabled: true,
    },
    {
      path: '/reporting',
      name: 'Reporting',
      icon: <ReportingIcon />,
      enabled: true,
    },
    {
      path: '/entities',
      name: 'Entities',
      icon: <EntitiesIcon />,
      enabled: true,
      children: [
        {
          path: `/sub-masters/${entity?._id}/edit`,
          name: 'Our Details',
          enabled: !!entity && [2].includes(activeRole.type),
        },
        {
          path: `/associations/${entity?._id}/edit`,
          name: 'Our Details',
          enabled: !!entity && [3].includes(activeRole.type),
        },
        {
          path: '/associations',
          name: 'Associations',
          enabled: [1, 2].includes(activeRole.type),
        },
        {
          path: '/sub-masters',
          name: 'Sub masters',
          enabled: [1].includes(activeRole.type),
        },
        {
          path: '/venues',
          name: 'Venues',
          enabled: true,
        },
      ],
    },
    {
      path: '/participation',
      name: 'Participation',
      icon: <ParticipationIcon />,
      enabled: true,
      children: [
        {
          path: '/teams',
          name: 'Teams',
          enabled: true,
        },
        {
          path: '/participants',
          name: 'Participants',
          enabled: true,
        },
      ],
    },
    {
      path: '/competitions',
      name: 'Competitions',
      icon: <CompetitionsIcon />,
      enabled: true,
      children: [
        {
          path: '/competitions',
          name: 'Competitions',
          enabled: true,
        },
        {
          path: '/draw-builder',
          name: 'Draw Builder',
          enabled: true,
        },
        {
          path: '/matches',
          name: 'Matches',
          enabled: true,
        },
        {
          path: '/incidents',
          name: 'Incidents',
          enabled: true,
        },
      ],
    },
    {
      path: '/configurations',
      name: 'Configurations',
      icon: <ConfigurationsIcon />,
      enabled: [1].includes(activeRole.type),
      children: [
        {
          path: '/seasons',
          name: 'Seasons',
          enabled: true,
        },
        {
          path: '/divisions',
          name: 'Divisions',
          enabled: true,
        },
        {
          path: '/ladder-settings',
          name: 'Ladder Settings',
          enabled: true,
        },
      ],
    },
    {
      path: '/payments',
      name: 'Payments',
      icon: <PaymentsIcon />,
      enabled:
        [3].includes(activeRole.type) &&
        !!entity &&
        (entity as Association).stripeChargesEnabled !== true,
      children: [
        {
          path: '/payments/onboarding',
          name: 'Get Started',
          enabled: true,
        },
      ],
    },
  ]
}
